/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à La Valette"), React.createElement("p", null, "Pour se loger, rappelons d’abord que La Valette est une citadelle bâtie sur une presqu’île. Les fortifications dominent deux havres naturels : Marsamxett Harbour au nord et Grand Harbour au sud, où se trouve le ", React.createElement("a", {
    href: "https://www.booking.com/landmark/mt/valletta-waterfront.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Grand Port"), "."), React.createElement("p", null, "La Republic Street parcourt toute la capitale d’ouest en est pour s’achever au pied du ", React.createElement("a", {
    href: "https://www.booking.com/landmark/mt/fort-st-elmo.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Fort Saint-Elme"), " :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "La première partie de la rue traverse ", React.createElement("a", {
    href: "https://www.booking.com/landmark/mt/national-museum-of-archaeology.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "la ville haute"), ", celle où l’on travaille. Les monuments historiques et les bâtiments officiels y sont concentrés, tout comme les restaurants et les terrasses de cafés."), "\n", React.createElement(_components.li, null, "La ", React.createElement("a", {
    href: "https://www.booking.com/landmark/mt/manoel-theatre.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "ville basse"), " est constituée des rues à l’est d’Archbishop Street, derrière le Palais magistral. C’est la partie résidentielle du centre historique, elle est célèbre pour ses ruelles tranquilles et ses façades colorées."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
